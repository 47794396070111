<template>
  <div class="content_body SaleProductCommissionScheme" v-loading="loading">
    <div class="nav_header">
      <el-row>
        <el-col :span="20">
          <el-form :inline="true" size="small" @submit.native.prevent>
            <el-form-item label="组织单位">
              <el-input
                v-model="name"
                placeholder="输入组织单位名称搜索"
                clearable
                @clear="handleSearch"
                @keyup.enter.native="handleSearch"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                size="small"
                @click="handleSearch"
                v-prevent-click
                >搜索</el-button
              >
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="4" class="text_right">
          <el-button
            type="primary"
            size="small"
            @click="showAddDialog"
            v-prevent-click
            >新增</el-button
          >
        </el-col>
      </el-row>
    </div>

    <div class="martp_10">
      <el-table size="small" :data="tableData">
        <el-table-column prop="EntityName" label="组织单位"></el-table-column>
        <el-table-column label="操作" width="145px">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="small"
              @click="showEditDialog(scope.row)"
              v-prevent-click
              >编辑</el-button
            >
            <el-button
              type="danger"
              size="small"
              @click="removeEntityClick(scope.row)"
              v-prevent-click
              :loading="modalLoading"
              v-if="isDelete"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="page pad_10">
        <div class="text_right" v-if="paginations.total > 0">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page.sync="paginations.page"
            :page-size="paginations.page_size"
            :layout="paginations.layout"
            :total="paginations.total"
          ></el-pagination>
        </div>
      </div>
    </div>

    <!--新增弹窗-->
    <el-dialog
      title="新增产品销售提成方案"
      :visible.sync="dialogVisible"
      width="520px"
    >
      <div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="120px"
          size="small"
        >
          <el-form-item label="适用组织" prop="EntityID">
            <span slot="label">
              适用组织
              <el-popover placement="top-start" width="200" trigger="hover">
                <p>适用于同级所有节点，则只需选择父节点。</p>
                <p>比如：适用于所有节点，只需选择“顶级/第一个”节点。</p>
                <p>
                  如需要设置经手人/职务提成，请选择已经配置经手人的组织单位。
                </p>
                <el-button
                  type="text"
                  style="color: #dcdfe6"
                  icon="el-icon-info"
                  slot="reference"
                ></el-button>
              </el-popover>
            </span>
            <treeselect
              v-model="ruleForm.EntityID"
              :options="entityList"
              :normalizer="normalizer"
              clearValueText
              noResultsText="无匹配数据"
              placeholder="选择适用组织"
            />
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false" v-prevent-click
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="small"
          :loading="modalLoading"
          v-prevent-click
          @click="submitProductCommissionScheme"
          >保存</el-button
        >
      </div>
    </el-dialog>

    <!--编辑弹窗-->
    <el-dialog :visible.sync="dialogEdit" width="70%">
      <div slot="title">{{ entityName }} - 产品分类销售提成方案</div>
      <el-table
        :data="productCategoryCommission"
        size="small"
        max-height="500px"
        :row-class-name="tableRowClassName"
        row-key="CategoryID"
        :tree-props="{ children: 'Child', hasChildren: 'hasChild' }"
      >
        <el-table-column
          prop="CategoryName"
          label="产品分类"
          min-width="150px"
          fixed
        ></el-table-column>
        <el-table-column prop="PayRate" label="现金比例" min-width="105px">
          <template slot-scope="scope">
            <el-input
              type="number"
              size="mini"
              min="0"
              v-model="scope.row.PayRate"
              v-enter-number
              class="input_type"
              @input="royaltyRateChange(1, scope.row)"
            >
              <template slot="append">%</template>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column prop="PayFixed" label="现金固定" min-width="115px">
          <template slot-scope="scope">
            <el-input
              type="number"
              size="mini"
              min="0"
              v-model="scope.row.PayFixed"
              v-enter-number2
              class="input_type"
            >
              <template slot="append">元</template>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column
          prop="SavingCardRate"
          label="卡抵扣比例"
          min-width="105px"
        >
          <template slot-scope="scope">
            <el-input
              type="number"
              size="mini"
              min="0"
              v-model="scope.row.SavingCardRate"
              v-enter-number
              class="input_type"
              @input="royaltyRateChange(2, scope.row)"
            >
              <template slot="append">%</template>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column
          prop="SavingCardFixed"
          label="卡抵扣固定"
          min-width="115px"
        >
          <template slot-scope="scope">
            <el-input
              type="number"
              size="mini"
              min="0"
              v-model="scope.row.SavingCardFixed"
              v-enter-number2
              class="input_type"
            >
              <template slot="append">元</template>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column
          prop="SavingCardLargessRate"
          label="赠送卡扣比例"
          min-width="105px"
        >
          <template slot-scope="scope">
            <el-input
              type="number"
              size="mini"
              min="0"
              v-model="scope.row.SavingCardLargessRate"
              v-enter-number
              class="input_type"
              @input="royaltyRateChange(3, scope.row)"
            >
              <template slot="append">%</template>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column
          prop="SavingCardLargessFixed"
          label="赠送卡扣固定"
          min-width="115px"
        >
          <template slot-scope="scope">
            <el-input
              type="number"
              size="mini"
              min="0"
              v-model="scope.row.SavingCardLargessFixed"
              v-enter-number2
              class="input_type"
            >
              <template slot="append">元</template>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column
          prop="SpecialBenefit"
          label="特殊补贴"
          min-width="115px"
        >
          <template slot-scope="scope">
            <el-input
              type="number"
              size="mini"
              v-model="scope.row.SpecialBenefit"
              min="0"
              v-enter-number2
              class="input_type"
            >
              <template slot="append">元</template>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="255px">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="small"
              @click="handerCommissionClick(0, scope.row)"
              v-if="scope.row.isEntity"
              >所有产品经手人/职务提成</el-button
            >
            <el-button
              type="primary"
              size="mini"
              @click="handerCommissionClick(1, scope.row)"
              v-if="!scope.row.isEntity"
              >分类经手人/职务提成</el-button
            >
            <el-button
              type="primary"
              size="mini"
              @click="productCommissionClick(scope.row)"
              v-if="!scope.row.isEntity"
              >产品提成</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogEdit = false" v-prevent-click
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="small"
          :loading="modalLoading"
          v-prevent-click
          @click="submitProductCategoryCommission"
          >保 存</el-button
        >
      </div>
    </el-dialog>

    <!--经手人弹窗-->
    <el-dialog :visible.sync="dialogHander" width="60%" v-loading="loading">
      <div slot="title" v-if="isHander != 2">
        {{ entityName }} - {{ categoryName }} - 经手人/职务销售提成方案
      </div>
      <div slot="title" v-else>
        {{ entityName }} - {{ categoryName }} - {{ productName }} -
        经手人/职务销售提成方案
      </div>
      <el-tabs v-model="handleActiveName" @tab-click="handleChangeClick">
        <el-tab-pane label="产品" name="0">
          <el-table
            :data="handerCommission"
            row-key="ID"
            :tree-props="{
              children: 'HandlerJobTypeCommission',
              hasChildren: 'hasChildren',
            }"
            max-height="500px"
            size="small"
          >
            <el-table-column
              prop="Name"
              label="经手人/职务"
              min-width="150px"
              fixed
            ></el-table-column>
            <el-table-column prop="PayRate" label="现金比例" min-width="105px">
              <template slot-scope="scope">
                <el-input
                  type="number"
                  v-model="scope.row.PayRate"
                  v-enter-number
                  class="input_type"
                  @input="royaltyRateChange(1, scope.row)"
                  size="mini"
                  min="0"
                >
                  <template slot="append">%</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column prop="PayFixed" label="现金固定" min-width="115px">
              <template slot-scope="scope">
                <el-input
                  type="number"
                  v-model="scope.row.PayFixed"
                  min="0"
                  v-enter-number2
                  class="input_type"
                  size="mini"
                >
                  <template slot="append">元</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column
              prop="SavingCardRate"
              label="卡扣比例"
              min-width="105px"
            >
              <template slot-scope="scope">
                <el-input
                  type="number"
                  v-model="scope.row.SavingCardRate"
                  v-enter-number
                  class="input_type"
                  @input="royaltyRateChange(2, scope.row)"
                  size="mini"
                  min="0"
                >
                  <template slot="append">%</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column
              prop="SavingCardFixed"
              label="卡扣固定"
              min-width="115px"
            >
              <template slot-scope="scope">
                <el-input
                  type="number"
                  v-model="scope.row.SavingCardFixed"
                  v-enter-number2
                  class="input_type"
                  size="mini"
                  min="0"
                >
                  <template slot="append">元</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column
              prop="SavingCardLargessRate"
              label="赠送卡扣比例"
              min-width="105px"
            >
              <template slot-scope="scope">
                <el-input
                  type="number"
                  v-model="scope.row.SavingCardLargessRate"
                  v-enter-number
                  class="input_type"
                  @input="royaltyRateChange(3, scope.row)"
                  size="mini"
                  min="0"
                >
                  <template slot="append">%</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column
              prop="SavingCardLargessFixed"
              label="赠送卡扣固定"
              min-width="115px"
            >
              <template slot-scope="scope">
                <el-input
                  type="number"
                  v-model="scope.row.SavingCardLargessFixed"
                  v-enter-number2
                  class="input_type"
                  size="mini"
                  min="0"
                >
                  <template slot="append">元</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column
              prop="SpecialBenefit"
              label="特殊补贴"
              min-width="115px"
            >
              <template slot-scope="scope">
                <el-input
                  type="number"
                  v-model="scope.row.SpecialBenefit"
                  v-enter-number2
                  class="input_type"
                  size="mini"
                  min="0"
                >
                  <template slot="append">元</template>
                </el-input>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="套餐卡-产品" name="1">
          <el-table
            :data="packageHanderCommission"
            row-key="ID"
            :tree-props="{
              children: 'HandlerJobTypeCommission',
              hasChildren: 'hasChildren',
            }"
            max-height="500px"
            size="small"
          >
            <el-table-column
              prop="Name"
              label="经手人/职务"
              min-width="150px"
              fixed
            ></el-table-column>
            <el-table-column prop="PayRate" label="现金比例" min-width="105px">
              <template slot-scope="scope">
                <el-input
                  type="number"
                  v-model="scope.row.PayRate"
                  v-enter-number
                  class="input_type"
                  @input="royaltyRateChange(1, scope.row)"
                  size="mini"
                  min="0"
                >
                  <template slot="append">%</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column prop="PayFixed" label="现金固定" min-width="115px">
              <template slot-scope="scope">
                <el-input
                  type="number"
                  v-model="scope.row.PayFixed"
                  min="0"
                  v-enter-number2
                  class="input_type"
                  size="mini"
                >
                  <template slot="append">元</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column
              prop="SavingCardRate"
              label="卡扣比例"
              min-width="105px"
            >
              <template slot-scope="scope">
                <el-input
                  type="number"
                  v-model="scope.row.SavingCardRate"
                  v-enter-number
                  class="input_type"
                  @input="royaltyRateChange(2, scope.row)"
                  size="mini"
                  min="0"
                >
                  <template slot="append">%</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column
              prop="SavingCardFixed"
              label="卡扣固定"
              min-width="115px"
            >
              <template slot-scope="scope">
                <el-input
                  type="number"
                  v-model="scope.row.SavingCardFixed"
                  v-enter-number2
                  class="input_type"
                  size="mini"
                  min="0"
                >
                  <template slot="append">元</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column
              prop="SavingCardLargessRate"
              label="赠送卡扣比例"
              min-width="105px"
            >
              <template slot-scope="scope">
                <el-input
                  type="number"
                  v-model="scope.row.SavingCardLargessRate"
                  v-enter-number
                  class="input_type"
                  @input="royaltyRateChange(3, scope.row)"
                  size="mini"
                  min="0"
                >
                  <template slot="append">%</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column
              prop="SavingCardLargessFixed"
              label="赠送卡扣固定"
              min-width="115px"
            >
              <template slot-scope="scope">
                <el-input
                  type="number"
                  v-model="scope.row.SavingCardLargessFixed"
                  v-enter-number2
                  class="input_type"
                  size="mini"
                  min="0"
                >
                  <template slot="append">元</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column
              prop="SpecialBenefit"
              label="特殊补贴"
              min-width="115px"
            >
              <template slot-scope="scope">
                <el-input
                  type="number"
                  v-model="scope.row.SpecialBenefit"
                  v-enter-number2
                  class="input_type"
                  size="mini"
                  min="0"
                >
                  <template slot="append">元</template>
                </el-input>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>

      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogHander = false" v-prevent-click
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="small"
          :loading="modalLoading"
          v-prevent-click
          @click="submitClick"
          >保存</el-button
        >
      </div>
    </el-dialog>

    <!--产品弹窗-->
    <el-dialog :visible.sync="dialogProject" width="65%">
      <div slot="title">
        {{ entityName }} - {{ categoryName }} - 产品销售提成方案
      </div>
      <div>
        <el-form :inline="true" size="small">
          <el-form-item>
            <el-input
              v-model="searchProductName"
              placeholder="输入产品名称搜索"
              prefix-icon="el-icon-search"
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
        <el-table
          :data="
            productCommission.filter(
              (data) =>
                !searchProductName ||
                data.ProductName.toLowerCase().includes(
                  searchProductName.toLowerCase()
                )
            )
          "
          max-height="500px"
          size="small"
        >
          <el-table-column
            prop="ProductName"
            label="产品名称"
            min-width="150px"
            fixed
          ></el-table-column>
          <el-table-column prop="PayRate" label="现金比例" min-width="105px">
            <template slot-scope="scope">
              <el-input
                type="number"
                v-model="scope.row.PayRate"
                v-enter-number
                class="input_type"
                @input="royaltyRateChange(1, scope.row)"
                size="mini"
                min="0"
              >
                <template slot="append">%</template>
              </el-input>
            </template>
          </el-table-column>
          <el-table-column prop="PayFixed" label="现金固定" min-width="115px">
            <template slot-scope="scope">
              <el-input
                type="number"
                v-model="scope.row.PayFixed"
                min="0"
                v-enter-number2
                class="input_type"
                size="mini"
              >
                <template slot="append">元</template>
              </el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="SavingCardRate"
            label="卡抵扣比例"
            min-width="105px"
          >
            <template slot-scope="scope">
              <el-input
                type="number"
                v-model="scope.row.SavingCardRate"
                v-enter-number
                class="input_type"
                @input="royaltyRateChange(2, scope.row)"
                size="mini"
                min="0"
              >
                <template slot="append">%</template>
              </el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="SavingCardFixed"
            label="卡抵扣固定"
            min-width="115px"
          >
            <template slot-scope="scope">
              <el-input
                type="number"
                v-model="scope.row.SavingCardFixed"
                v-enter-number2
                class="input_type"
                size="mini"
                min="0"
              >
                <template slot="append">元</template>
              </el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="SavingCardLargessRate"
            label="赠送卡扣比例"
            min-width="105px"
          >
            <template slot-scope="scope">
              <el-input
                type="number"
                v-model="scope.row.SavingCardLargessRate"
                v-enter-number
                class="input_type"
                @input="royaltyRateChange(3, scope.row)"
                size="mini"
                min="0"
              >
                <template slot="append">%</template>
              </el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="SavingCardLargessFixed"
            label="赠送卡扣固定"
            min-width="115px"
          >
            <template slot-scope="scope">
              <el-input
                type="number"
                v-model="scope.row.SavingCardLargessFixed"
                v-enter-number2
                class="input_type"
                size="mini"
                min="0"
              >
                <template slot="append">元</template>
              </el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="SpecialBenefit"
            label="特殊补贴"
            min-width="115px"
          >
            <template slot-scope="scope">
              <el-input
                type="number"
                v-model="scope.row.SpecialBenefit"
                v-enter-number2
                class="input_type"
                size="mini"
                min="0"
              >
                <template slot="append">元</template>
              </el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="115px">
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="mini"
                @click="handerCommissionClick(2, scope.row)"
                >经手人提成</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogProject = false" v-prevent-click
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="small"
          :loading="modalLoading"
          v-prevent-click
          @click="submitProductCommission"
          >保存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import APIEntity from "@/api/KHS/Entity/entity";
import API from "@/api/iBeauty/HanderCommission/saleProductCommissionScheme";
import permission from "@/components/js/permission.js";

var Enumerable = require("linq");
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  components: { Treeselect },
  name: "SaleProductCommissionScheme",
  data() {
    return {
      loading: false,
      modalLoading: false,
      dialogVisible: false,
      dialogEdit: false,
      dialogHander: false,
      dialogProject: false,
      isDelete: false,
      name: "",
      entityList: [],
      tableData: [],
      isHander: 1,
      searchProductName: "",
      entityID: "",
      categoryID: "",
      productID: "",
      entityName: "",
      categoryName: "",
      productName: "",
      productCategoryCommission: [],
      handerCommission: [],
      packageHanderCommission: [],//套餐卡产品
      productCommission: [],
      ruleForm: {
        EntityID: null,
      },
      rules: {
        EntityID: [
          { required: true, message: "请选择组织", trigger: "change" },
        ],
      },
      //需要给分页组件传的信息
      paginations: {
        page: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      },
      handleActiveName:"0" // 产品 套餐卡产品切换
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.isDelete = permission.permission(
        to.meta.Permission,
        "iBeauty-HanderCommission-SaleProductCommissionScheme-Delete"
      );
    });
  },
  methods: {
    normalizer(node) {
      return {
        id: node.ID,
        label: node.EntityName,
        children: node.Child,
      };
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex === 0) {
        return "info-row";
      }
      return "";
    },
    // 所属单位
    entityData: function () {
      var that = this;
      APIEntity.getEntityAll()
        .then((res) => {
          if (res.StateCode == 200) {
            that.entityList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 数据显示
    handleSearch: function () {
      let that = this;
      that.paginations.page = 1;
      that.search();
    },
    // 数据显示
    search: function () {
      let that = this;
      that.loading = true;
      var params = {
        Name: that.name,
        PageNum: that.paginations.page,
      };
      API.getProductCommissionScheme(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.tableData = res.List;
            that.paginations.total = res.Total;
            that.paginations.page_size = res.PageSize;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 上下分页
    handleCurrentChange(page) {
      var that = this;
      that.paginations.page = page;
      that.search();
    },
    // 产品销售分类提佣方案
    productCategoryCommissionData: function () {
      var that = this;
      that.loading = true;
      var params = {
        EntityID: that.entityID,
      };
      API.getProductCategoryCommission(params)
        .then((res) => {
          if (res.StateCode == 200) {
            var data = {
              CategoryID: "0" + res.Data.EntityID,
              CategoryName: "所有产品",
              PayFixed: res.Data.PayFixed,
              PayRate: res.Data.PayRate,
              SavingCardFixed: res.Data.SavingCardFixed,
              SavingCardLargessFixed: res.Data.SavingCardLargessFixed,
              SavingCardLargessRate: res.Data.SavingCardLargessRate,
              SavingCardRate: res.Data.SavingCardRate,
              SpecialBenefit: res.Data.SpecialBenefit,
              isEntity: true,
            };
            var Category = Enumerable.from(res.Data.Category)
              .select((val) => ({
                CategoryID: val.CategoryID,
                CategoryName: val.CategoryName,
                PayFixed: val.PayFixed,
                PayRate: val.PayRate,
                SavingCardFixed: val.SavingCardFixed,
                SavingCardLargessFixed: val.SavingCardLargessFixed,
                SavingCardLargessRate: val.SavingCardLargessRate,
                SavingCardRate: val.SavingCardRate,
                SpecialBenefit: val.SpecialBenefit,
                isEntity: false,
                Child: val.Child,
              }))
              .toArray();
            that.productCategoryCommission = Object.assign([], Category);
            that.productCategoryCommission.unshift(data);
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 产品销售分类经手人提佣方案
    ProductCategoryHandlerCommissionData: function () {
      var that = this;
      that.loading = true;
      var params = {
        EntityID: that.entityID,
        CategoryID: that.categoryID,
      };
      API.getProductCategoryHandlerCommission(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.handerCommission = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 套餐卡--产品销售分类经手人提佣方案
    PackageCardSaleProductCategoryHandlerCommissionData: function () {
      var that = this;
      that.loading = true;
      var params = {
        EntityID: that.entityID,
        CategoryID: that.categoryID,
      };
      API.getPackageCardSaleProductCategoryHandlerCommission(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.packageHanderCommission = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },


    // 分类下的产品提佣
    ProductCommissionData: function () {
      var that = this;
      that.loading = true;
      var params = {
        EntityID: that.entityID,
        CategoryID: that.categoryID,
      };
      API.getProductCommission(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.productCommission = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 产品下经手人提佣
    ProductHanderCommissionData: function () {
      var that = this;
      that.loading = true;
      var params = {
        EntityID: that.entityID,
        ProductID: that.productID,
      };
      API.getProductHanderCommission(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.handerCommission = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },

    // 套餐卡 产品下经手人提佣
    PackageCardSaleProductHandlerCommission: function () {
      var that = this;
      that.loading = true;
      var params = {
        EntityID: that.entityID,
        ProductID: that.productID,
      };
      API.getPackageCardSaleProductHandlerCommission(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.packageHanderCommission = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 所有产品经手人提佣
    ProductSchemeHanderCommissionData: function () {
      var that = this;
      that.loading = true;
      var params = {
        EntityID: that.entityID,
      };
      API.getProductSchemeHanderCommission(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.handerCommission = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
     // 31.14.获取所有套餐卡产品经手人提佣方案
    PackageProductSchemeHanderCommissionData: function () {
      var that = this;
      that.loading = true;
      var params = {
        EntityID: that.entityID,
      };
      API.getPackageCardProductSchemeHanderCommission(params)
        .then((res) => {
          if (res.StateCode == 200) {
            console.log('000000');
            that.packageHanderCommission = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },


    // 新增
    showAddDialog: function () {
      var that = this;
      that.ruleForm = {
        EntityID: null,
      };
      that.dialogVisible = true;
    },
    // 编辑
    showEditDialog: function (row) {
      var that = this;
      that.entityName = row.EntityName;
      that.entityID = row.EntityID;
      that.productCategoryCommissionData();
      that.dialogEdit = true;
    },
    //经手人提成
    handerCommissionClick: function (index, row) {
      var that = this;
      that.isHander = index;
      that.handerCommission = [];
      that.handleActiveName = "0";
      if (index == 0) {
        that.categoryName = row.CategoryName;
        that.ProductSchemeHanderCommissionData();
        // 套餐卡
        that.PackageProductSchemeHanderCommissionData();
      } else if (index == 1) {
        that.categoryID = row.CategoryID;
        that.categoryName = row.CategoryName;
        that.ProductCategoryHandlerCommissionData();
        /**  套餐卡 产品 分类  */
        that.PackageCardSaleProductCategoryHandlerCommissionData();
      } else {
        that.productID = row.ProductID;
        that.productName = row.ProductName;
        that.ProductHanderCommissionData();
        that.PackageCardSaleProductHandlerCommission();
      }

      that.dialogHander = true;
    },
    // 项目提成设置
    productCommissionClick: function (row) {
      var that = this;
      that.categoryID = row.CategoryID;
      that.categoryName = row.CategoryName;
      that.ProductCommissionData();
      that.dialogProject = true;
    },
    // 创建产品销售组织单位提成
    submitProductCommissionScheme: function () {
      var that = this;
      that.$refs.ruleForm.validate((valid) => {
        if (valid) {
          that.modalLoading = true;
          let para = Object.assign({}, that.ruleForm);
          API.createProductCommissionScheme(para)
            .then(function (res) {
              if (res.StateCode === 200) {
                that.$message.success({
                  message: "新增成功",
                  duration: 2000,
                });
                that.search();
                that.$refs["ruleForm"].resetFields();
                that.dialogVisible = false;
              } else {
                that.$message.error({
                  message: res.Message,
                  duration: 2000,
                });
              }
            })
            .finally(function () {
              that.modalLoading = false;
            });
        }
      });
    },
    // 保存产品销售分类提佣方案
    submitProductCategoryCommission: function () {
      var that = this;
      that.modalLoading = true;
      var params = {
        EntityID: that.productCategoryCommission[0].CategoryID,
        PayRate: that.productCategoryCommission[0].PayRate,
        PayFixed: that.productCategoryCommission[0].PayFixed,
        SavingCardRate: that.productCategoryCommission[0].SavingCardRate,
        SavingCardFixed: that.productCategoryCommission[0].SavingCardFixed,
        SavingCardLargessRate:
          that.productCategoryCommission[0].SavingCardLargessRate,
        SavingCardLargessFixed:
          that.productCategoryCommission[0].SavingCardLargessFixed,
        SpecialBenefit: that.productCategoryCommission[0].SpecialBenefit,
      };

      var Category = that.productCategoryCommission;
      Category = Enumerable.from(Category)
        .where(function (i) {
          return !i.isEntity;
        })
        .toArray();

      Category.forEach(function (item) {
        item.Child = Enumerable.from(item.Child)
          .where(function (i) {
            return (
              (i.PayFixed !== "" && i.PayFixed !== null) ||
              (i.PayRate !== "" && i.PayRate !== null) ||
              (i.SavingCardFixed !== "" && i.SavingCardFixed !== null) ||
              (i.SavingCardRate !== "" && i.SavingCardRate !== null) ||
              (i.SavingCardLargessFixed !== "" &&
                i.SavingCardLargessFixed !== null) ||
              (i.SavingCardLargessRate !== "" &&
                i.SavingCardLargessRate !== null) ||
              (i.SpecialBenefit !== "" && i.SpecialBenefit !== null)
            );
          })
          .toArray();
      });

      Category = Enumerable.from(Category)
        .where(function (i) {
          return (
            (i.PayFixed !== "" && i.PayFixed !== null) ||
            (i.PayRate !== "" && i.PayRate !== null) ||
            (i.SavingCardFixed !== "" && i.SavingCardFixed !== null) ||
            (i.SavingCardRate !== "" && i.SavingCardRate !== null) ||
            (i.SavingCardLargessFixed !== "" &&
              i.SavingCardLargessFixed !== null) ||
            (i.SavingCardLargessRate !== "" &&
              i.SavingCardLargessRate !== null) ||
            (i.SpecialBenefit !== "" && i.SpecialBenefit !== null) ||
            i.Child.length > 0
          );
        })
        .toArray();



/**  

      var productCategoryCommission = Enumerable.from(that.productCategoryCommission)
        .where(function (i) {
          return (
            (i.PayFixed !== "" && i.PayFixed !== null) ||
            (i.PayRate !== "" && i.PayRate !== null) ||
            (i.SavingCardFixed !== "" && i.SavingCardFixed !== null) ||
            (i.SavingCardRate !== "" && i.SavingCardRate !== null) ||
            (i.SavingCardLargessFixed !== "" &&
              i.SavingCardLargessFixed !== null) ||
            (i.SavingCardLargessRate !== "" &&
              i.SavingCardLargessRate !== null) ||
            (i.SpecialBenefit !== "" && i.SpecialBenefit !== null)
          );
        })
        .toArray();

      productCategoryCommission.forEach(function (item) {
        item.Child = Enumerable.from(item.Child)
          .where(function (i) {
            return (
              (i.PayFixed !== "" && i.PayFixed !== null) ||
              (i.PayRate !== "" && i.PayRate !== null) ||
              (i.SavingCardFixed !== "" && i.SavingCardFixed !== null) ||
              (i.SavingCardRate !== "" && i.SavingCardRate !== null) ||
              (i.SavingCardLargessFixed !== "" &&
                i.SavingCardLargessFixed !== null) ||
              (i.SavingCardLargessRate !== "" &&
                i.SavingCardLargessRate !== null) ||
              (i.SpecialBenefit !== "" && i.SpecialBenefit !== null)
            );
          })
          .toArray();
      });

      var Category = Enumerable.from(productCategoryCommission)
        .where(function (i) {
          return !i.isEntity;
        })
        .select((val) => ({
          CategoryID: val.CategoryID,
          PayFixed: val.PayFixed,
          PayRate: val.PayRate,
          SavingCardFixed: val.SavingCardFixed,
          SavingCardLargessFixed: val.SavingCardLargessFixed,
          SavingCardLargessRate: val.SavingCardLargessRate,
          SavingCardRate: val.SavingCardRate,
          SpecialBenefit: val.SpecialBenefit,
          Child:val.Child,
        }))
        .toArray();

          */


      params.Category = Category;
      API.updateProductCategoryCommission(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("提成设置成功");
            that.dialogEdit = false;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.modalLoading = false;
        });
    },
    // 保存产品销售分类经手人提成
    submitProductCategoryHandlerCommission: function () {
      var that = this;
      var handerCommission = that.handerCommission;
      handerCommission.forEach(function (item) {
        item.HandlerJobTypeCommission = Enumerable.from(
          item.HandlerJobTypeCommission
        )
          .where(function (i) {
            return (
              (i.PayFixed !== "" && i.PayFixed !== null) ||
              (i.PayRate !== "" && i.PayRate !== null) ||
              (i.SavingCardFixed !== "" && i.SavingCardFixed !== null) ||
              (i.SavingCardRate !== "" && i.SavingCardRate !== null) ||
              (i.SavingCardLargessFixed !== "" &&
                i.SavingCardLargessFixed !== null) ||
              (i.SavingCardLargessRate !== "" &&
                i.SavingCardLargessRate !== null) ||
              (i.SpecialBenefit !== "" && i.SpecialBenefit !== null)
            );
          })
          .toArray();
      });

      handerCommission = Enumerable.from(handerCommission)
        .where(function (i) {
          return (
            (i.PayFixed !== "" && i.PayFixed !== null) ||
            (i.PayRate !== "" && i.PayRate !== null) ||
            (i.SavingCardFixed !== "" && i.SavingCardFixed !== null) ||
            (i.SavingCardRate !== "" && i.SavingCardRate !== null) ||
            (i.SavingCardLargessFixed !== "" &&
              i.SavingCardLargessFixed !== null) ||
            (i.SavingCardLargessRate !== "" &&
              i.SavingCardLargessRate !== null) ||
            (i.SpecialBenefit !== "" && i.SpecialBenefit !== null) ||
            i.HandlerJobTypeCommission.length > 0
          );
        })
        .select((val) => ({
          SaleHandlerID: val.ID,
          PayRate: val.PayRate,
          PayFixed: val.PayFixed,
          SavingCardRate: val.SavingCardRate,
          SavingCardFixed: val.SavingCardFixed,
          SavingCardLargessRate: val.SavingCardLargessRate,
          SavingCardLargessFixed: val.SavingCardLargessFixed,
          SpecialBenefit: val.SpecialBenefit,
          HandlerJobTypeCommission: val.HandlerJobTypeCommission,
        }))
        .toArray();

      var packageHanderCommission = that.packageHanderCommission
      packageHanderCommission.forEach(function (item) {
        item.HandlerJobTypeCommission = Enumerable.from(
          item.HandlerJobTypeCommission
        )
          .where(function (i) {
            return (
              (i.PayFixed !== "" && i.PayFixed !== null) ||
              (i.PayRate !== "" && i.PayRate !== null) ||
              (i.SavingCardFixed !== "" && i.SavingCardFixed !== null) ||
              (i.SavingCardRate !== "" && i.SavingCardRate !== null) ||
              (i.SavingCardLargessFixed !== "" &&
                i.SavingCardLargessFixed !== null) ||
              (i.SavingCardLargessRate !== "" &&
                i.SavingCardLargessRate !== null) ||
              (i.SpecialBenefit !== "" && i.SpecialBenefit !== null)
            );
          })
          .toArray();
      });
      
      packageHanderCommission = Enumerable.from(packageHanderCommission)
        .where(function (i) {
          return (
            (i.PayFixed !== "" && i.PayFixed !== null) ||
            (i.PayRate !== "" && i.PayRate !== null) ||
            (i.SavingCardFixed !== "" && i.SavingCardFixed !== null) ||
            (i.SavingCardRate !== "" && i.SavingCardRate !== null) ||
            (i.SavingCardLargessFixed !== "" &&
              i.SavingCardLargessFixed !== null) ||
            (i.SavingCardLargessRate !== "" &&
              i.SavingCardLargessRate !== null) ||
            (i.SpecialBenefit !== "" && i.SpecialBenefit !== null) ||
            i.HandlerJobTypeCommission.length > 0
          );
        })
        .select((val) => ({
          SaleHandlerID: val.ID,
          PayRate: val.PayRate,
          PayFixed: val.PayFixed,
          SavingCardRate: val.SavingCardRate,
          SavingCardFixed: val.SavingCardFixed,
          SavingCardLargessRate: val.SavingCardLargessRate,
          SavingCardLargessFixed: val.SavingCardLargessFixed,
          SpecialBenefit: val.SpecialBenefit,
          HandlerJobTypeCommission: val.HandlerJobTypeCommission,
        }))
        .toArray();



      that.modalLoading = true;
      var params = {
        EntityID: that.entityID,
        CategoryID: that.categoryID,
        ProductHandler: handerCommission,
        PackageCardProductHandler:packageHanderCommission
      };
      API.updateProductCategoryHandlerCommission(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("提成设置成功");
            that.dialogHander = false;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.modalLoading = false;
        });
    },
    // 保存分类下的产品销售提成方案
    submitProductCommission: function () {
      var that = this;
      var productCommission = Enumerable.from(that.productCommission)
        .where(function (i) {
          return (
            (i.PayFixed !== "" && i.PayFixed !== null) ||
            (i.PayRate !== "" && i.PayRate !== null) ||
            (i.SavingCardFixed !== "" && i.SavingCardFixed !== null) ||
            (i.SavingCardRate !== "" && i.SavingCardRate !== null) ||
            (i.SavingCardLargessFixed !== "" &&
              i.SavingCardLargessFixed !== null) ||
            (i.SavingCardLargessRate !== "" &&
              i.SavingCardLargessRate !== null) ||
            (i.SpecialBenefit !== "" && i.SpecialBenefit !== null)
          );
        })
        .toArray();
      that.modalLoading = true;
      var params = {
        EntityID: that.entityID,
        Product: productCommission,
        CategoryID: that.categoryID,
      };
      API.updateProductCommission(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("提成设置成功");
            that.dialogProject = false;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.modalLoading = false;
        });
    },
    // 保存产品下经手人提成方案
    submitProductHanderCommission: function () {
      var that = this;
      var handerCommission = that.handerCommission;

      handerCommission.forEach(function (item) {
        item.HandlerJobTypeCommission = Enumerable.from(
          item.HandlerJobTypeCommission
        )
          .where(function (i) {
            return (
              (i.PayFixed !== "" && i.PayFixed !== null) ||
              (i.PayRate !== "" && i.PayRate !== null) ||
              (i.SavingCardFixed !== "" && i.SavingCardFixed !== null) ||
              (i.SavingCardRate !== "" && i.SavingCardRate !== null) ||
              (i.SavingCardLargessFixed !== "" &&
                i.SavingCardLargessFixed !== null) ||
              (i.SavingCardLargessRate !== "" &&
                i.SavingCardLargessRate !== null) ||
              (i.SpecialBenefit !== "" && i.SpecialBenefit !== null)
            );
          })
          .toArray();
      });
      handerCommission = Enumerable.from(handerCommission)
        .where(function (i) {
          return (
            (i.PayFixed !== "" && i.PayFixed !== null) ||
            (i.PayRate !== "" && i.PayRate !== null) ||
            (i.SavingCardFixed !== "" && i.SavingCardFixed !== null) ||
            (i.SavingCardRate !== "" && i.SavingCardRate !== null) ||
            (i.SavingCardLargessFixed !== "" &&
              i.SavingCardLargessFixed !== null) ||
            (i.SavingCardLargessRate !== "" &&
              i.SavingCardLargessRate !== null) ||
            (i.SpecialBenefit !== "" && i.SpecialBenefit !== null) ||
            i.HandlerJobTypeCommission.length > 0
          );
        })
        .select((val) => ({
          SaleHandlerID: val.ID,
          PayRate: val.PayRate,
          PayFixed: val.PayFixed,
          SavingCardRate: val.SavingCardRate,
          SavingCardFixed: val.SavingCardFixed,
          SavingCardLargessRate: val.SavingCardLargessRate,
          SavingCardLargessFixed: val.SavingCardLargessFixed,
          SpecialBenefit: val.SpecialBenefit,
          HandlerJobTypeCommission: val.HandlerJobTypeCommission,
        }))
        .toArray();


      var packageHanderCommission = that.packageHanderCommission;
      
      packageHanderCommission.forEach(function (item) {
        item.HandlerJobTypeCommission = Enumerable.from(
          item.HandlerJobTypeCommission
        )
          .where(function (i) {
            return (
              (i.PayFixed !== "" && i.PayFixed !== null) ||
              (i.PayRate !== "" && i.PayRate !== null) ||
              (i.SavingCardFixed !== "" && i.SavingCardFixed !== null) ||
              (i.SavingCardRate !== "" && i.SavingCardRate !== null) ||
              (i.SavingCardLargessFixed !== "" &&
                i.SavingCardLargessFixed !== null) ||
              (i.SavingCardLargessRate !== "" &&
                i.SavingCardLargessRate !== null) ||
              (i.SpecialBenefit !== "" && i.SpecialBenefit !== null)
            );
          })
          .toArray();
      });
      packageHanderCommission = Enumerable.from(packageHanderCommission)
        .where(function (i) {
          return (
            (i.PayFixed !== "" && i.PayFixed !== null) ||
            (i.PayRate !== "" && i.PayRate !== null) ||
            (i.SavingCardFixed !== "" && i.SavingCardFixed !== null) ||
            (i.SavingCardRate !== "" && i.SavingCardRate !== null) ||
            (i.SavingCardLargessFixed !== "" &&
              i.SavingCardLargessFixed !== null) ||
            (i.SavingCardLargessRate !== "" &&
              i.SavingCardLargessRate !== null) ||
            (i.SpecialBenefit !== "" && i.SpecialBenefit !== null) ||
            i.HandlerJobTypeCommission.length > 0
          );
        })
        .select((val) => ({
          SaleHandlerID: val.ID,
          PayRate: val.PayRate,
          PayFixed: val.PayFixed,
          SavingCardRate: val.SavingCardRate,
          SavingCardFixed: val.SavingCardFixed,
          SavingCardLargessRate: val.SavingCardLargessRate,
          SavingCardLargessFixed: val.SavingCardLargessFixed,
          SpecialBenefit: val.SpecialBenefit,
          HandlerJobTypeCommission: val.HandlerJobTypeCommission,
        }))
        .toArray();

      that.modalLoading = true;
      var params = {
        EntityID: that.entityID,
        ProductID: that.productID,
        ProductHandler: handerCommission,
        PackageCardProductHandler:packageHanderCommission
      };
      API.updateProductHanderCommission(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("提成设置成功");
            that.dialogHander = false;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.modalLoading = false;
        });
    },
    // 保存所有产品经手人提成方案
    submitProductSchemeHanderCommission: function () {
      var that = this;
      var handerCommission = that.handerCommission;
      handerCommission.forEach(function (item) {
        item.HandlerJobTypeCommission = Enumerable.from(
          item.HandlerJobTypeCommission
        )
          .where(function (i) {
            return (
              (i.PayFixed !== "" && i.PayFixed !== null) ||
              (i.PayRate !== "" && i.PayRate !== null) ||
              (i.SavingCardFixed !== "" && i.SavingCardFixed !== null) ||
              (i.SavingCardRate !== "" && i.SavingCardRate !== null) ||
              (i.SavingCardLargessFixed !== "" &&
                i.SavingCardLargessFixed !== null) ||
              (i.SavingCardLargessRate !== "" &&
                i.SavingCardLargessRate !== null) ||
              (i.SpecialBenefit !== "" && i.SpecialBenefit !== null)
            );
          })
          .toArray();
      });
      handerCommission = Enumerable.from(handerCommission)
        .where(function (i) {
          return (
            (i.PayFixed !== "" && i.PayFixed !== null) ||
            (i.PayRate !== "" && i.PayRate !== null) ||
            (i.SavingCardFixed !== "" && i.SavingCardFixed !== null) ||
            (i.SavingCardRate !== "" && i.SavingCardRate !== null) ||
            (i.SavingCardLargessFixed !== "" &&
              i.SavingCardLargessFixed !== null) ||
            (i.SavingCardLargessRate !== "" &&
              i.SavingCardLargessRate !== null) ||
            (i.SpecialBenefit !== "" && i.SpecialBenefit !== null) ||
            i.HandlerJobTypeCommission.length > 0
          );
        })
        .select((val) => ({
          SaleHandlerID: val.ID,
          PayRate: val.PayRate,
          PayFixed: val.PayFixed,
          SavingCardRate: val.SavingCardRate,
          SavingCardFixed: val.SavingCardFixed,
          SavingCardLargessRate: val.SavingCardLargessRate,
          SavingCardLargessFixed: val.SavingCardLargessFixed,
          SpecialBenefit: val.SpecialBenefit,
          HandlerJobTypeCommission: val.HandlerJobTypeCommission,
        }))
        .toArray();

      var packageHanderCommission = that.packageHanderCommission;
      packageHanderCommission = Enumerable.from(packageHanderCommission)
        .where(function (i) {
          return (
            (i.PayFixed !== "" && i.PayFixed !== null) ||
            (i.PayRate !== "" && i.PayRate !== null) ||
            (i.SavingCardFixed !== "" && i.SavingCardFixed !== null) ||
            (i.SavingCardRate !== "" && i.SavingCardRate !== null) ||
            (i.SavingCardLargessFixed !== "" &&
              i.SavingCardLargessFixed !== null) ||
            (i.SavingCardLargessRate !== "" &&
              i.SavingCardLargessRate !== null) ||
            (i.SpecialBenefit !== "" && i.SpecialBenefit !== null) ||
            i.HandlerJobTypeCommission.length > 0
          );
        })
        .select((val) => ({
          SaleHandlerID: val.ID,
          PayRate: val.PayRate,
          PayFixed: val.PayFixed,
          SavingCardRate: val.SavingCardRate,
          SavingCardFixed: val.SavingCardFixed,
          SavingCardLargessRate: val.SavingCardLargessRate,
          SavingCardLargessFixed: val.SavingCardLargessFixed,
          SpecialBenefit: val.SpecialBenefit,
          HandlerJobTypeCommission: val.HandlerJobTypeCommission,
        }))
        .toArray();

      that.modalLoading = true;
      var params = {
        EntityID: that.entityID,
        ProductHandler: handerCommission,
        PackageCardProductHandler: packageHanderCommission,
      };
      API.updateProductSchemeHanderCommission(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("提成设置成功");
            that.dialogHander = false;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.modalLoading = false;
        });
    },
    submitClick: function () {
      var that = this;
      if (that.isHander == 0) {
        that.submitProductSchemeHanderCommission();
      } else if (that.isHander == 1) {
        that.submitProductCategoryHandlerCommission();
      } else {
        that.submitProductHanderCommission();
      }
    },
    // 删除
    removeEntityClick: function (row) {
      var that = this;
      that
        .$confirm("此操作将永久删除该记录, 是否继续?", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          that.modalLoading = false;
          var params = {
            EntityID: row.EntityID,
          };
          API.deleteProductCommissionScheme(params)
            .then((res) => {
              if (res.StateCode == 200) {
                that.$message.success("删除成功");
                that.search();
              } else {
                that.$message.error({
                  message: res.Message,
                  duration: 2000,
                });
              }
            })
            .finally(function () {
              that.modalLoading = false;
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 提成比例
    royaltyRateChange: function (index, row) {
      if (index == 1) {
        if (row.PayRate > 100) {
          row.PayRate = 100;
        }
      } else if (index == 2) {
        if (row.SavingCardRate > 100) {
          row.SavingCardRate = 100;
        }
      } else if (index == 3) {
        if (row.SavingCardLargessRate > 100) {
          row.SavingCardLargessRate = 100;
        }
      }
    },

    /**    */
    handleChangeClick(){
      console.log("00000");
    },
  
  },
  mounted() {
    var that = this;

    that.isDelete = permission.permission(
      that.$route.meta.Permission,
      "iBeauty-HanderCommission-SaleProductCommissionScheme-Delete"
    );
    that.handleSearch();
    that.entityData();
  },
};
</script>

<style lang="scss">
.SaleProductCommissionScheme{

  .input_type {
    .el-input-group__append {
      padding: 0 10px;
    }
  }
  .el-input__inner {
    padding-right: 0;
  }

  .el-table .info-row {
    background: #c0c4cc;
  }

}
</style>
